
<template>
<el-upload
  class="upload-demo"
  ref="upload"
  :auto-upload="false"
>
  <template #trigger>
    <el-button size="small" type="primary">select file</el-button>
  </template>
  <el-button
    style="margin-left: 10px;"
    size="small"
    type="success"
    @click="submitUpload"
    >upload to server</el-button
  >
  <template #tip>
    <div class="el-upload__tip">
      JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
    </div>
  </template>
</el-upload>
</template>

<script>
import AuthService from "@/api/auth.service";
import BuildingService from "@/api/building.service";

  export default {
    methods: {
      submitUpload() {
        //this.$refs.upload.submit()
		return BuildingService.uploadimage(this.upload).then(response => {
                // this.data = response.data
				console.log(response.data.data)
                return response
              });
      },
    },
  }
</script>

<style>

</style>
